@font-face {
    font-family: 'Verona';
    src: local('Verona'), url(../../fonts/Verona-Serial-Regular.ttf) format('truetype');
  }
  
  @font-face {
    font-family: 'VeronaBold';
    src: local('VeronaBold'), url(../../fonts/verona-serial-bold.ttf) format('truetype');
  }
  html {
    scroll-behavior: smooth;
  }
  

 h5 {
     font-size: 1.1em !important;

  }

.landing-alert{
    margin-bottom: 0;
}

.navbar {
    font-size: 20px !important;
    font-family: VeronaBold !important;
    color: #efcab8 !important;
}

.navbar-brand {
    font-size: 20px !important;
    font-family: VeronaBold !important;
    color: #efcab8 !important;

}
.header-button {
    padding: 15px 50px;
    font-size: 18px;
    background-color: #a00015;
    border-color: #a00015;
    color: #f4c1ad;
}
.header-button-other {
    padding: 15px 50px;
    font-size: 18px;
    background-color: #a00015;
    border-color: #a00015;
    color: #f4c1ad;
    margin-left: 40px;
}

.nav-item {
    margin: auto;
}

.navbar .navbar-nav .nav-link:not(.btn) {
    text-transform: uppercase;
    font-size: 0.8142em !important;
    padding: 0.5rem 0.7rem;
    line-height: 1.625rem;
    font-family: 'Montserrat', "Helvetica", Arial, sans-serif !important;
}

.title-new { 
    font-weight: 700;
    // padding-top: 30px;
    font-size: 2em;
    margin-bottom: 30px;
    // font-weight: 400;
  color: #f6c8b5;
  text-align: center;
    font-family: 'VeronaBold';
}

.footer-bg {
    background: #931c1e;
    color: #edc3b0;

}
.description {
    color: #f6c8b5 !important;
    font-family: "Montserrat", "Helvetica", Arial, sans-serif;
    font-weight: 500;
}

.newsletter-image {
        background: #f4c1ad;
        z-index: 20000;
        opacity: 0.9;
        padding: 80px 250px;
        border-radius: 10px;
        margin-top: 300px;
        box-shadow: 5px 5px rgba(210, 127, 113, 0.5), 5px 5px rgba(210, 127, 113, 0.5);
}
.title-new .h2 {
    font-size: 2.5em;
    margin-bottom: 30px;
    font-weight: 400;
  color: #f6c8b5;
}

.fixed {
    top: 0;
    position: fixed;
   right: 0;
    left: 0;
    z-index: 1030;
}

.highlight {
    position: fixed;
    color: #231f20;
    font-weight: 500;
    font-family: "Montserrat", "Helvetica", Arial, sans-serif;
    display:block;
    padding: 10px 0; height: 40px; background-color:#edc3b0;width: 100%;display: block;text-align: center; font-size: 14px;
}

.navbar .navbar-nav .nav-item.active .nav-link:not(.btn), .navbar .navbar-nav .nav-item .nav-link:not(.btn):focus, .navbar .navbar-nav .nav-item .nav-link:not(.btn):hover, .navbar .navbar-nav .nav-item .nav-link:not(.btn):active {
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 0.1875rem;
}

.fixed-top{
    top: 40px;
}

.nav-link {
    font-size: 20px !important;
    font-family: VeronaBold !important;
    color: #efcab8 !important;

}
.navbar-color{
    background: #931c1e;
}
.page-header{
    background-color: #B2AFAB;
    background-position: center center;
    background-size: cover;
    min-height: 100vh;
    max-height: 999px;
    overflow: hidden;
    position: relative;
    width: 100%;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    .filter::after{
        background-color: linear-gradient(0deg, rgba(44, 44, 44, 0.2), rgba(189, 46, 46, 0.6));
        content: "";
        display: block;
        height: 100%;
        left: 0;
        top: 0;
        position: absolute;
        width: 100%;
        z-index: 1;
    }
    &.page-header-small{
        min-height: 65vh !important;
        max-height: 700px;
    }
    &.page-header-xs{
        min-height: 40vh !important;
    }

    &.section-dark{
        .content-center,
        .category-absolute{
            z-index: 1;
        }
    }
}
.page-header .motto{
    text-align: left;
    z-index: 3;
    color: #fff;
    position: relative;

}
.landing-section{
    padding: 100px 0;
    z-index: 1;
}
.section-buttons{
    z-index: 1;
}
.landing-section .btn-simple{
    padding: 0;
}
.landing-section .column{
    padding: 0 75px 0 25px;
}
.team-player .img-circle, .team-player .img-thumbnail{
    display: block;
    margin-top: 50px;
    margin-left: auto;
    margin-right: auto;
    width: 120px;
}
.contact-form{
    margin-top: 30px;
}
.contact-form label{
    margin-top: 15px;
}
.contact-form .btn{
    margin-top: 30px;
}

.navbar-relative{
    position: relative;
    z-index: 2;
}
#register-navbar a{
    color: #FFF;
}
.register-background .container{
    margin-top: 11%;
    position: relative;
    z-index: 3;
}
.register-footer{
    bottom: 20px;
    position: absolute;
    z-index: 1;
    width: 100%;
    background: transparent;
    color: $white-color;
}
.register-footer .fa-heart{
    color: #EB5E28;
}

.register-card label{
    margin-top: 15px;
}
.register-card .title{
    color: #B33C12;
    text-align: center;
}
.register-card .btn{
    margin-top: 30px;
}
.register-card .forgot{
    text-align: center;
}

.profile-content{
    position: relative;
}
.owner{
    text-align: center;
}
.owner .avatar{
    padding: 15px;
    max-width: 180px;
    margin: -85px auto 0;
    display: inline-block;
}
.owner .name h4{
    margin-top: 10px;
}
.profile-tabs{
    margin: 50px 0;
    min-height: 300px;
}
#following h3{
    margin: 20px 0 40px 0;
}

#follows .follows .unfollow .checkbox{
    margin-top: -15px;
}
#follows .follows h6{
    margin-top: 15px;
}
.follows hr{
    margin-top: 10px;
}
.alert h5{
    // margin-bottom: 10px;
}
.title-brand{
    max-width: 880px;
    margin: 0 auto;
    position: relative;
    text-align: center;
    color: #FFFFFF;
    display: block;

    .type{
        position: absolute;
        font-size: 20px;
        background: #132026;
        padding: 6px 10px;
        border-radius: 4px;
        top: 0;
        font-weight: 600;
        margin-top: 10px;
        right: -15px;
    }
}

.clear-filter[filter-color="orange"] {
    background: rgba(44, 44, 44, 0.2);
    /* For browsers that do not support gradients */
    background: -webkit-linear-gradient(90deg, rgba(44, 44, 44, 0.2), rgba(189, 46, 46, 0.6));
    /* For Safari 5.1 to 6.0 */
    background: -o-linear-gradient(90deg, rgba(44, 44, 44, 0.2), rgba(189, 46, 46, 0.6));
    /* For Opera 11.1 to 12.0 */
    background: -moz-linear-gradient(90deg, rgba(44, 44, 44, 0.2), rgba(189, 46, 46, 0.6));
    /* For Firefox 3.6 to 15 */
    background: linear-gradient(0deg, rgba(44, 44, 44, 0.2), rgba(189, 46, 46, 0.6));
    /* Standard syntax */
  }
  
  .articles {
    color: #bd2e2e;
font-family: 'Verona' !important;
  
  }

  .container-pad {
    padding-left: 100px;
    padding-right: 100px;
  }

  .blog-header {
    max-height: 1300px;
  }
  .card-deck {
      padding-top: 2rem;
    //   padding-bottom: 2rem;
  }

  .cherry-button {
    color: #f6c8b5;
    border: #f6c8b5 1px solid;
    font-size: 19px;
  }

  .cherry-button-news {
    background-color: #df7a6c;
    border: #f6c8b5 1px solid;
    font-size: 15px;
    color: #fec5b0;
    border-radius: 0;
    margin-left: -25px;
    padding: 5px 20px !important;
  }

  .btn-outline-info:hover, .btn-outline-info:active, .btn-outline-info.active,  .btn-outline-info:active:hover,  .show > .btn-outline-info.dropdown-toggle {
    background-color: #f6c8b5 !important;
    color: #980018 !important;
    border-color: #980018 !important;
   
}

  .cherry-latest {
    color: #980018;
    border:#980018 1px solid;
    margin-bottom: 30px;

  }

  .instagram-section {
    max-height: 1200px;
    padding-bottom: 5rem;
    padding-top: 2rem;
    min-height: 500px;
  }
  .card {
    border: 0;
    border-radius: 0.1875rem;
    display: inline-block;
    position: relative;
    width: 100%;
    margin-bottom: 30px;
    box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.2);
    background: #f6c8b5;
    border-radius: 15px;
  }

  .card-title  {
    font-family: VeronaBold;
  }
  .card-text {
    font-family: "Montserrat", "Helvetica", Arial, sans-serif;
    font-size: 17px !important;
    color: #231f20 !important;
    text-align: left;
    padding-bottom: 15px;
    padding-top: 15px;
  
  }
  .stigma {
    color: #980018;
    font-family: VeronaBold;
  }
  
  .author {
    text-align: left;
    font-size: 15px;
  }
  
  .instagram {
      padding: 0;
  }

  .instagram img {
      height: 400px;
  }

  .instagram a:hover {
      background-color: #931c1e !important;
  }

  .pillar-paragraph {
    font-family: "Montserrat", "Helvetica", Arial, sans-serif;
    padding-top: 20px;
    text-align: center;
    color:#bd2e2e !important;
    font-size: 18px !important;
    font-weight: 500;
  }

  .form-control {
    border: #fcbea8 2px solid !important;
}


  .pillar-container {
      padding-left: 100px;
      padding-right: 100px;
  }


  .pillars {
      text-align: center;
      font-family: 'Verona' !important;

      
  }

.presentation-title{
    font-family: VeronaBold;
    font-size: 4.4em;
    text-transform: uppercase;
    font-weight: 300;
    letter-spacing: 2px;
    color: #f6c8b5;
}
.presentation-subtitle{
    font-size: 1.7em;
    color: #FFFFFF;
}

.category-absolute{
    position: absolute;
    text-align: center;
    top: 100vh;
    margin-top: -60px;
    padding: 0 15px;
    width: 100%;
    color: rgba(255, 255, 255, 0.5);

    .creative-tim-logo {
        max-width: 140px;
        top: -2px;
        left: 3px;
        position: relative;
    }
}

.fog-low{
    position: absolute;
    left: 0;
    bottom: 0;
    margin-left: -35%;
    margin-bottom: -50px;
    width: 110%;
    opacity: .85;

    img{
        width: 100%;
    }

    &.right{
        margin-left: 30%;
        opacity: 1;
    }
}
.page-header{
    .content-center{
        margin-top: 85px;
    }
}
.moving-clouds{
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    width: 250.625em;
    height: 43.75em;
    -webkit-animation: cloudLoop 80s linear infinite;
    animation: cloudLoop 80s linear infinite;
}

@keyframes cloudLoop{   0%{-webkit-transform:translate3d(0,0,0);transform:translate3d(0,0,0)}
                        100%{-webkit-transform:translate3d(-50%,0,0);
                        transform:translate3d(-50%,0,0)}
                    }
.following{
    img{
        max-width: 70px;
    }
}


.profile-content{
    & .tab-content{
        & .tab-pane{
            min-height: 200px;
        }
        & #tweets, #connections, #media{
            height: 100%;
        }
    }
}

.btn-outline-info:hover, .btn-outline-info:focus, .btn-outline-info:active, .btn-outline-info.active, .btn-outline-info:active:focus, .btn-outline-info:active:hover, .btn-outline-info.active:focus, .show > .btn-outline-info.dropdown-toggle {
    background-color: #edc3b0 !important;
    color: rgba(255, 255, 255, 0.8);
    border-color:#edc3b0  !important;
}

.btn-info:hover, .btn-info:focus, .btn-info:active, .btn-info.active, .btn-info:active:focus, .btn-info:active:hover, .btn-info.active:focus, .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active, .btn-info:not(:disabled):not(.disabled).active:focus, .show > .btn-info.dropdown-toggle {
    background-color: #edc3b0 !important;
    color: #B33C12 !important;
    border: 1px solid #edc3b0 !important;
    box-shadow: none;
}

.animated_right { 
    background-image: url('../../../assets/img/cherry_right.png'); 
    background-repeat: no-repeat;
    background-position: center; 
    animation-duration: 10s;
      animation-iteration-count:infinite;
    animation-duration: 10s; 
    -webkit-animation-fill-mode: both; 
    animation-fill-mode: both; 
    height: 200px;
  }
  
  @-webkit-keyframes swing { 
    20%, 40%, 60%, 80%, 100% { -webkit-transform-origin: top center; } 
    20% { -webkit-transform: rotate(15deg); } 
    40% { -webkit-transform: rotate(-10deg); } 
    60% { -webkit-transform: rotate(5deg); } 
    80% { -webkit-transform: rotate(-5deg); } 
    100% { -webkit-transform: rotate(0deg); } 
  }
  
  @keyframes swing { 
    20% { transform: rotate(15deg); } 
    40% { transform: rotate(-10deg); } 
    60% { transform: rotate(5deg); } 
    80% { transform: rotate(-5deg); } 
    100% { transform: rotate(0deg); } 
  }
  
  .swing { 
    -webkit-transform-origin: top center; 
    transform-origin: top center; 
    -webkit-animation-name: swing; 
    animation-name: swing; 
  }


  ._3xnQP:hover {
    opacity: 0.7 !important;
    background-color: #95190e !important;
  }

  .pillar-height{
      min-height: 302px;
  }

.blog-padding {
    padding-top: 200px;
    padding-bottom: 200px;
}

  @media(max-width: 1200px){ 
    .content-center {
        margin-bottom: 30px;
    }

    .margin-read {
        margin-left: 0 !important;
    }

    .cherry-button {
        font-size: 13px;
        margin-bottom: 40px;
    }

    .cherry-button-spotify {
        float: none !important;
        margin-left: 10px !important;

    }

  .pillar-paragraph {
    font-family: "Montserrat", "Helvetica", Arial, sans-serif;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 30px;
    padding-right: 30px;
    text-align: center;
    color: #bd2e2e !important;
    font-size: 18px !important;
}



.pillar-container {
    padding: 0;
}

.pillar-height{
    min-height: 203px;
    
}

.page-header {
    max-height: fit-content;
}

.description {
    font-size: 18px;
    font-family: "Montserrat", "Helvetica", Arial, sans-serif;
    font-weight: 500;
    text-align: center;
}

.blog-padding{
    padding: 0;
}

.title-new {
    font-size: 1.8em;
}

.container-pad {
    padding-left: 30px;
    padding-right: 30px;
}

.animated_right {
    background-size: 120px 94px;
    padding-top: 20px;
    height: 100px;

}

.card-text {
    font-size: 16px !important;

}

.navbar .navbar-collapse:after {
    background-color: #940010;
}
.navbar-brand {
    display: none;
}

.fixed-top {
    height: 60px;
}

.highlight {
    height: 50px;
    font-size: 12px;

}

.mobile {
    margin-left: 50%;
    display: block;
}
.Product {
    flex:  100% !important;
    // padding-right: 30px;
    // padding-left: 30px;
    padding-bottom: 30px;
    text-align: center;
}

.button {
    font-size: 1.4rem !important; 
    float: right;

}

.newsletter-image {
    padding: 30px 10px;
    margin-top: 330px;
}

.title-newsletter {
    font-size: 1.3em;
    text-align: center;
    font-family: Verona;


}

.newsletter-text {
    font-family: "Montserrat", "Helvetica", Arial, sans-serif;
    font-weight: 500;
    padding-top: 10px;
    padding-bottom: 20px;
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
    color: #bd2e2e !important;
    font-size: 15px !important;
}

.chats-bg {
    background-repeat: no-repeat !important;
    background-size: contain !important;
    background:#e99d93 url("../../img/Heart2_mb.png") !important;

}

.cherry-hosts-desc {
    margin-left: 0 !important;
}

.cherry-hosts {
    padding-top: 470px;
    margin-left: 0 !important;
}


}

.mobile {
    display:none;
}

.btn {
    float: right;
}


.header-btn {
    float: none;
    
}


.title-newsletter {
    font-size: 1.6em;
    text-align: center;
    font-family: Verona;


}

.newsletter-text {
    font-family: "Montserrat", "Helvetica", Arial, sans-serif;
    font-weight: 500;
    padding-top: 10px;
    padding-bottom: 20px;
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
    color: #bd2e2e !important;
    font-size: 16px !important;
}

.chats-bg {
    background:#bd2e2e url("../../img/Heart2.png");
          background-position: center;
          background-size: cover;
          background-repeat: no-repeat !important;

}

.chats-button {
    max-height: 1000px;
    width: 100vw;
}

.cherry-button-spotify {
    background-color: #d27e71;
    border: #d27e71 1px solid;
    font-size: 20px;
    /* border-radius: 40px; */
    color: #efcab8;
    margin-left: -80px;
  
    border-radius: 5px;
    /* margin-left: -25px; */
    padding: 10px 40px !important;
    min-width: 330px;
  }

  .cherry-button-spotify:hover {
    background-color: #d27e71;
    border: #d27e71 1px solid;
    font-size: 20px;
    /* border-radius: 40px; */
    color: #efcab8;
    margin-left: -80px;
  
    border-radius: 5px;
    /* margin-left: -25px; */
    padding: 10px 40px !important;
    min-width: 330px;
  }

  .cherry-hosts {
      font-size: 19px;
      margin-left: -80px;
      padding-bottom: 20px;
      font-family: "Montserrat", "Helvetica", Arial, sans-serif;
  }

  .cherry-hosts-desc {
    font-size: 18px;
    font-weight: 600;
    margin-left: -80px;
    padding-bottom: 20px;
}

.contact-text {
    text-align: center;
    font-size: 22px;
}

.contact-text-two {
    font-size: 22px;
    text-align: center;
    padding-bottom: 30px;

}

.contact-button {
    float: left;
    font-size: 16px;
}

.contact-card-header {
        padding: 0;
        margin-bottom: 0;
        background-color: rgba(246, 200, 181, 1);
        border-bottom: 1px solid rgba(246, 200, 181, 1);
}


.contact-bg {
    background: #efcab8;
    border: 1px solid #66615b !important;
    color: #66615b !important;
}

.contact-bg:focus {
    background: #efcab8;
    border: 1px solid #66615b !important;
    color: #66615b !important;

}

.contact-bg::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #66615b !important;
    opacity: 1; /* Firefox */
  }
  
  .contact-bg:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color:#66615b;
  }
  
  .contact-bg::-ms-input-placeholder { /* Microsoft Edge */
    color:#66615b
  }

  .margin-read {
      margin-left: 40px;
  }

.reads {
    color: #fec5b0;
    font-weight: bold;
}

.reads:hover {
    color: #bd2e2e;
}

.header-btn:hover {
    background-color: #fec5b0;
    border: #fec5b0 solid 1px;
    color: #bd2e2e;

}